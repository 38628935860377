export const itemsMenu: IItemsMenu[] = [
  {
    type: 'menu',
    menuName: 'antipasti',
    title: {
      it: 'Antipasti',
      en: 'Starter',
    },
    animation: 'fade-right',
  },
  {
    type: 'menu',
    menuName: 'primi',
    title: {
      it: 'Primi',
      en: 'First Course',
    },
    animation: 'fade-left',
  },
  {
    type: 'menu',
    menuName: 'secondi',
    title: {
      it: 'Secondi',
      en: 'Second Course',
    },
    animation: 'fade-right',
  },
  {
    type: 'menu',
    menuName: 'dessert',
    title: {
      it: 'Dolci',
      en: 'Dessert',
    },
    animation: 'fade-left',
  },
];

export const itemsVini: IItemsMenu[] = [
  {
    type: 'vini',
    menuName: 'bianchi',
    title: {
      it: 'I Bianchi',
      en: 'White Wines',
    },
    animation: 'fade-right',
  },
  {
    type: 'vini',
    menuName: 'rossi',
    title: {
      it: 'I Rossi',
      en: 'Red Wines',
    },
    animation: 'fade-left',
  },
  {
    type: 'vini',
    menuName: 'bollicine',
    title: {
      it: 'Le Bollicine',
      en: 'Sparkling Wines',
    },
    animation: 'fade-right',
  },
];
