import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import casabarbabuc from '../secrets/casabarbabuc.json';
import { getStorage } from 'firebase/storage';

const app = initializeApp(casabarbabuc);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
