import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase/firebase-config';
import { AccordionDetails, Box, Typography } from '@mui/material';
import { formatter } from '../../utils';

interface IDisplayMenu extends ILang {
  menuName: string;

  type: 'menu' | 'vini';
}

const menuConverter: FirestoreDataConverter<IItemMenu> = {
  toFirestore(props): DocumentData {
    return props;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IItemMenu {
    const { id } = snapshot;
    const { archiviato, description_en, description_it, price, name_en, name_it } = snapshot.data(options)!;
    return {
      archiviato,
      id,
      description_en,
      description_it,
      price,
      name_en,
      name_it,
    };
  },
};

const DislayItemMenu: React.FC<IDisplayMenu> = ({ lang, menuName, type }) => {
  const dataCol = collection(db, type, menuName, 'lista').withConverter(menuConverter);
  const q = query(dataCol, where('archiviato', '==', false), orderBy('name_it'));
  const [values] = useCollectionData(q);

  return (
    <AccordionDetails>
      {values?.map((value) => (
        <Box key={value.id}>
          <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'} pt={2} pb={2}>
            <Typography variant='h5'>{value[`name_${lang}`]}</Typography>
            <Box borderBottom={'1px dotted'} flexGrow={1} />
            <Typography variant='h5'>{formatter.format(value.price / 10)}</Typography>
          </Box>
          <Typography variant='body1'>{value[`description_${lang}`]}</Typography>
        </Box>
      ))}
    </AccordionDetails>
  );
};

export default DislayItemMenu;
