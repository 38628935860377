import React from 'react';
import { Box } from '@mui/material';

const Map = () => {
  return (
    <Box sx={{ marginTop: 'var(--margin-section)' }}>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2841.3777752185138!2d7.925354015799011!3d44.589298199412006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d2af90b3ba9b83%3A0xd2c8e888025ec3a9!2sCasa%20Barbabuc!5e0!3m2!1sit!2sit!4v1642754905921!5m2!1sit!2sit'
        title='mappa'
        height={'360px'}
        width={'100%'}
        allowFullScreen
        loading='lazy'
      />
    </Box>
  );
};

export default Map;
