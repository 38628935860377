import React from 'react';
import { Typography } from '@mui/material';

interface ITitle {
  title: string;
  id?: string;
}

const Title: React.FC<ITitle> = ({ title, id }) => {
  return (
    <Typography
      id={id}
      variant='h1'
      textAlign={'center'}
      fontFamily={'var(--font-title)'}
      color={'secondary'}
      mt={'var(--margin-section)'}
    >
      {title}
    </Typography>
  );
};

export default Title;
