import {
  Box,
  Container,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  SvgIconTypeMap,
} from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import {
  collection,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { db } from '../../firebase/firebase-config';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface IContacts extends ILang {
  id: string;
}

const TitleSection = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.secondary.main,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  paddingBottom: 0,
}));

interface IOrari {
  day: string;
  openingTime: { closed: string; open: string }[];
}

export const orariConverter: FirestoreDataConverter<IOrari> = {
  toFirestore(props): DocumentData {
    return { ...props };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IOrari {
    const { day, openingTime } = snapshot.data(options)!;
    return {
      day,
      openingTime,
    };
  },
};

const Contact: React.FC<{ contactType: string; Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> }> = ({
  contactType,
  Icon,
}) => {
  const contattiCol = collection(db, 'contatti', contactType, 'lista');
  const [contatti] = useCollectionData(contattiCol);

  if (!contatti?.length) {
    return <></>;
  }

  return (
    <Cell>
      <Grid container spacing={2} p={0} m={0}>
        <Grid p={0}>
          <Icon />
        </Grid>
        <Grid>
          {contatti?.map((item, i) => (
            <span key={i}>
              {item.dati}
              <br />
            </span>
          ))}
        </Grid>
      </Grid>
    </Cell>
  );
};

const Contacts: React.FC<IContacts> = ({ id, lang }) => {
  const orariCol = collection(db, 'time').withConverter(orariConverter);
  const [orari] = useCollectionData(orariCol);

  return (
    <Container maxWidth='lg' sx={{ marginTop: 'var(--margin-section)', overflow: 'hidden' }}>
      <Grid container spacing={4} id={id}>
        <Grid data-aos='fade-right' xs={12} md={6}>
          <Box boxShadow={5} p={'16px'} height={'350px'} bgcolor={'background.paper'}>
            <TitleSection>{lang === 'it' ? 'I nostri orari' : 'Our opening hours'}</TitleSection>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {orari?.map(({ day, openingTime }) => (
                    <TableRow key={day}>
                      <Cell sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{day}</Cell>
                      {openingTime.map(({ closed, open }, i) => (
                        <Cell key={`${day}${i}`}>
                          {i === 0 && (open ? `${open} - ${closed}` : lang === 'it' ? 'Chiuso' : 'Closed')}
                          {i === 1 && open && `${open} - ${closed}`}
                        </Cell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Grid data-aos='fade-left' xs={12} md={6}>
          <Box boxShadow={5} p={'16px'} height={'350px'} bgcolor={'background.paper'}>
            <TitleSection>{lang === 'it' ? 'Contatti' : 'Contacts'}</TitleSection>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <Contact contactType='indirizzo' Icon={LocationOnIcon} />
                  </TableRow>
                  <TableRow>
                    <Contact contactType='telefono' Icon={PhoneIcon} />
                  </TableRow>
                  <TableRow>
                    <Contact contactType='cellulare' Icon={PhoneIphoneIcon} />
                  </TableRow>
                  <TableRow>
                    <Contact contactType='email' Icon={EmailIcon} />
                  </TableRow>
                  <TableRow>
                    <Contact contactType='fax' Icon={FaxIcon} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contacts;
