import React from 'react';
import { Box, Fade, useScrollTrigger } from '@mui/material';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const ScrollTop: React.FC<Props> = ({ children, window }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 250,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#backToTop');

    if (anchor) {
      anchor.scrollIntoView({ block: 'center' });
      console.log('anchor :>> ', anchor);
    }
  };
  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role='presentation' sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Fade>
  );
};

export default ScrollTop;
