import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

export const getYPosition = (id: string, offset = 0) => {
  const element = document.getElementById(id);
  if (element) {
    const y = element.offsetTop + offset;
    return y;
  }
  return 0;
};

export const formatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

// Mischia i valori di un'array
export function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}

/**
 * Ritorna le dimensioni di un immagine a partire dal link
 * @param url string
 * @returns { width, height }
 */
export const getMeta = (url: string): { width: number; height: number } => {
  const img = new Image();
  img.src = url;
  const { width, height } = img;
  return { width, height };
};

export const sliceIntoChunks = (arr: any, chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const imageConverter: FirestoreDataConverter<IPhoto> = {
  toFirestore(props): DocumentData {
    return { ...props };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): IPhoto {
    const { id } = snapshot;
    const { url } = snapshot.data(options)!;
    return {
      id,
      src: url,
      name: id.split('.')[0],
    };
  },
};
