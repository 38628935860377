import React from 'react';
import { Container, Typography, Card, CardContent, Box, Avatar } from '@mui/material';
import '../../App.css';
import { doc } from 'firebase/firestore';
import { db } from '../../firebase/firebase-config';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Title from '../Title';

const textBody = (lang: string) => {
  if (lang === 'it') {
    return (
      <>
        <Typography variant='h6' component={'i'} color='secondary' fontWeight={'bold'}>
          Casa Barbabuc
        </Typography>{' '}
        nasce nel 2021 dall'idea di Laura ed Antonella di creare un osteria con cucina di langa, camere confortevoli e
        una bottega con prodotti del territorio. Finalmente a Maggio il progetto prende vita. L'aria informale e
        familiare rendono{' '}
        <Typography variant='h6' component={'i'} color='secondary' fontWeight={'bold'}>
          Casa Barbabuc
        </Typography>{' '}
        ideale per chi vuole gustare piatti locali oppure un aperitivo in compagnia, fermarsi sul territorio qualche
        giorno in totale relax o acquistare prodotti di langa. Lo staff Vi aspetta per accogliervi al meglio.
      </>
    );
  }
  if (lang === 'en') {
    return (
      <>
        <Typography variant='h6' component={'i'} color='secondary' fontWeight={'bold'}>
          Casa Barbabuc
        </Typography>{' '}
        established in 2021 and born from an idea that Laura and Antonella had to create an inn with typical Langa
        cuisine, comfortable rooms and a shop selling local produce. In May 2021 the project took life. The informal
        family atmosphere make{' '}
        <Typography variant='h6' component={'i'} color='secondary' fontWeight={'bold'}>
          Casa Barbabuc
        </Typography>{' '}
        Barbabuc ideal for both a taste of local dishes or a drink with friends, stay a while in the area and immerse
        yourself in total relaxation o make a purchase of typical Langa products. Our staff is looking forward to
        welcome you.
      </>
    );
  }
};

interface IAbout extends ILang {}

const About: React.FC<IAbout> = ({ lang }) => {
  const docRef = doc(db, 'immagini', 'logo.svg');
  const [logo] = useDocumentData(docRef);
  return (
    <Container maxWidth='lg' style={{ minHeight: '100vh' }}>
      <Title title='Casa Barbabuc' id='about' />

      <Card data-aos='fade-up-right'>
        <Box display='flex' justifyContent='center' alignItems='center' p={2}>
          <Avatar
            className='shadow-sm '
            alt='Logo'
            src={logo?.url}
            variant='square'
            style={{ width: '200px', height: '130px' }}
          />
        </Box>

        <CardContent>
          <Typography variant='h6' color='text.primary'>
            {textBody(lang)}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default About;
