import { Accordion, Box, Container, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';
import DislayItemMenu from '../DisplayItemMenu';
import Title from '../Title';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IMenu extends ILang {
  title: string;
  id: string;
  items: IItemsMenu[];
}

const Menu: React.FC<IMenu> = ({ lang, title, id, items }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth='lg' style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Title title={title} id={id} />
      <Box boxShadow={5}>
        {items.map(({ animation, menuName, title, type }, i) => (
          <Box data-aos={animation} key={i}>
            <Accordion
              disableGutters
              elevation={0}
              expanded={expanded === `panel-${i}`}
              onChange={handleChange(`panel-${i}`)}
              square
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel-${menuName}`}>
                <Typography variant='h4' color={'secondary'}>
                  {title[lang]}
                </Typography>
              </AccordionSummary>
              <DislayItemMenu lang={lang} menuName={menuName} type={type} />
            </Accordion>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Menu;
