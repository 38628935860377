const images = [
  { id: 'Camera 10.jpg', src: 'assets/img/camere/Camera 10.jpg', name: 'Camera 10.jpg' },
  { id: 'Camera 12.jpg', src: 'assets/img/camere/Camera 12.jpg', name: 'Camera 12.jpg' },
  { id: 'Camera 1.jpg', src: 'assets/img/camere/Camera 1.jpg', name: 'Camera 1.jpg' },
  { id: 'Camera 2.jpg', src: 'assets/img/camere/Camera 2.jpg', name: 'Camera 2.jpg' },
  { id: 'Camera 3.jpg', src: 'assets/img/camere/Camera 3.jpg', name: 'Camera 3.jpg' },
  { id: 'Camera 6.jpg', src: 'assets/img/camere/Camera 6.jpg', name: 'Camera 6.jpg' },
  { id: 'Camera 9.jpg', src: 'assets/img/camere/Camera 9.jpg', name: 'Camera 9.jpg' },
  { id: 'Camera 11.jpg', src: 'assets/img/camere/Camera 11.jpg', name: 'Camera 11.jpg' },
  { id: 'camera-1.jpg', src: 'assets/img/camere/camera-1.jpg', name: 'camera-1.jpg' },
  { id: 'camera-2.jpg', src: 'assets/img/camere/camera-2.jpg', name: 'camera-2.jpg' },
  { id: 'camera-3.jpg', src: 'assets/img/camere/camera-3.jpg', name: 'camera-3.jpg' },
  { id: 'Camera 5.jpg', src: 'assets/img/camere/Camera 5.jpg', name: 'Camera 5.jpg' },
  { id: 'Camera 7.jpg', src: 'assets/img/camere/Camera 7.jpg', name: 'Camera 7.jpg' },
];
export default images;
