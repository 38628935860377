import { Box, Container, Typography, useMediaQuery } from '@mui/material';
// import { collection, query, where } from 'firebase/firestore';
import React from 'react';
// import { useCollectionData } from 'react-firebase-hooks/firestore';
// import { db } from '../../firebase/firebase-config';
// import { imageConverter } from '../../utils';
import Carousel from '../Carousel';
import Title from '../Title';
import { useTheme } from '@mui/material/styles';
import images from './image';

const Camere: React.FC<ISection> = ({ id, title }) => {
  // const dataCol = collection(db, 'immagini').withConverter(imageConverter);
  // const q = query(dataCol, where('cartella', '==', 'camere'));
  // const [images] = useCollectionData(q);
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <Container maxWidth='lg' style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Title id={id} title={title} />

      <Box data-aos='flip-up' boxShadow={5} p={'16px'} sx={{ backgroundColor: 'background.paper' }} mt={2}>
        <Typography variant='h6'>
          <Typography variant='h6' component={'i'} color='secondary' fontWeight={'bold'}>
            Casa Barbabuc
          </Typography>{' '}
          propone otto camere di cui cinque doppie e tre triple. Tutte dispongono di bagno privato, tv, bollitore,
          climatizzatore e wifi gratuito.
        </Typography>
      </Box>

      {images && (
        <Carousel
          photos={images}
          sx={{ backgroundColor: 'background.paper', marginTop: 2 }}
          randomize
          itemPerPage={isExtraSmallScreen ? 6 : 12}
        />
      )}
    </Container>
  );
};

export default Camere;
