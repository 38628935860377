import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'yet-another-react-lightbox/styles.css';

function App() {
  const [lang, setLang] = useState<'it' | 'en'>('it');

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  useEffect(() => {
    AOS.init({ duration: 2000, easing: 'ease-in-out', once: true, mirror: false });
  }, []);
  return (
    <Container style={{ padding: 0 }} maxWidth={false}>
      <Header lang={lang} setLang={setLang} />
      <Body lang={lang} />
    </Container>
  );
}

export default App;
