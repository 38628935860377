import React from 'react';
import { Box, Divider, Link, Typography, Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GoogleIcon from '@mui/icons-material/Google';

const logo =
  'https://firebasestorage.googleapis.com/v0/b/casa-barbabuc-95ff1.appspot.com/o/img%2Flogo_mceasyweb.png?alt=media&token=444955e5-7aae-4a95-ae81-6a18102ecdc0';

const Footer = () => {
  return (
    <Box bgcolor={'#212529'} mt={'var(--margin-section)'} pt={'10px'}>
      <Typography variant='h1' align='center'>
        Seguici su
      </Typography>
      <Box textAlign='center' mt={'10px'}>
        <Link href='https://www.facebook.com/casabarbabuc/' underline='none' target='_blank' color='text.primary'>
          <FacebookIcon className='iconSize' />
        </Link>
        <Link
          href='https://www.instagram.com/casabarbabuc.novello'
          underline='none'
          target='_blank'
          color='text.primary'
        >
          <InstagramIcon className='iconSize' />
        </Link>
        <Link href='https://wa.me/+393489341682' underline='none' target='_blank' color='text.primary'>
          <WhatsAppIcon className='iconSize' />
        </Link>
        <Link
          href='https://www.google.com/travel/hotels/*/entity/ChgIqYf7koCRuuTSARoLL2cvMXRxa3J5MWoQAQ/reviews?*=%2Fentity%2FCgsIqYf7koCRuuTSARAB%2Freviews&g2lb=4596364%2C4258168%2C4401769%2C4371334%2C4685401%2C4624411%2C4306835%2C4605861%2C4640247%2C4641139%2C4270442%2C2503781%2C4649665%2C2502548%2C4317915%2C4692343%2C4647135%2C4695402%2C4419364%2C4597339%2C4308227%2C2503771%2C4291517%2C4270859%2C4284970&hl=it-IT&gl=it&cs=1&ssta=1&rp=OAFAAEgBwAEC&ictx=1&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESCgoCCAMKAggDEAAaWAo6EjYyJTB4MTJkMmFmOTBiM2JhOWI4MzoweGQyYzhlODg4MDI1ZWMzYTk6DUNhc2EgQmFyYmFidWMaABIaEhQKBwjmDxABGB8SBwjmDxACGAEYATICEAAqCwoHKAE6A0VVUhoA&ap=MAE'
          underline='none'
          target='_blank'
          color='text.primary'
        >
          <GoogleIcon className='iconSize' />
        </Link>
        <Divider color='white' sx={{ margin: '0px 100px' }} />
        <Container maxWidth={'lg'}>
          <Box justifyContent={'space-around'} alignItems='center' p={`50px`} sx={{ display: { md: 'flex' } }}>
            <Typography>© 2021 Casa Barbabuc, All Right Reserved.</Typography>
            <Typography
              display={'flex'}
              justifyContent='center'
              alignItems='center'
              sx={{ marginTop: { xs: '10px', md: 0 } }}
            >
              Powerd <img src={`${logo}`} alt={'Logo mcEasyWeb'} width='50' height='50' style={{ margin: '0 5px' }} />{' '}
              by &nbsp;
              <Link target={'_blank'} href='https://mceasyweb.it/' color='text.primary'>
                mcEasyWeb
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
