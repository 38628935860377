const images = [
  { id: 'IMG20220122135433.jpg', src: 'assets/img/bottega/IMG20220122135433.jpg', name: 'IMG20220122135433' },
  { id: 'IMG20220122135519.jpg', src: 'assets/img/bottega/IMG20220122135519.jpg', name: 'IMG20220122135519' },
  { id: 'IMG20220122135637.jpg', src: 'assets/img/bottega/IMG20220122135637.jpg', name: 'IMG20220122135637' },
  { id: 'IMG20220122140457.jpg', src: 'assets/img/bottega/IMG20220122140457.jpg', name: 'IMG20220122140457' },
  { id: 'IMG20220122140611.jpg', src: 'assets/img/bottega/IMG20220122140611.jpg', name: 'IMG20220122140611' },
  { id: 'IMG20220122143830.jpg', src: 'assets/img/bottega/IMG20220122143830.jpg', name: 'IMG20220122143830' },
  { id: 'IMG20220122145416.jpg', src: 'assets/img/bottega/IMG20220122145416.jpg', name: 'IMG20220122145416' },
  { id: 'IMG20220122135441.jpg', src: 'assets/img/bottega/IMG20220122135441.jpg', name: 'IMG20220122135441' },
  { id: 'IMG20220122135544.jpg', src: 'assets/img/bottega/IMG20220122135544.jpg', name: 'IMG20220122135544' },
  { id: 'IMG20220122135652.jpg', src: 'assets/img/bottega/IMG20220122135652.jpg', name: 'IMG20220122135652' },
  { id: 'IMG20220122140558.jpg', src: 'assets/img/bottega/IMG20220122140558.jpg', name: 'IMG20220122140558' },
  { id: 'IMG20220122140618.jpg', src: 'assets/img/bottega/IMG20220122140618.jpg', name: 'IMG20220122140618' },
  { id: 'IMG20220122144459.jpg', src: 'assets/img/bottega/IMG20220122144459.jpg', name: 'IMG20220122144459' },
  { id: 'IMG20220122145648.jpg', src: 'assets/img/bottega/IMG20220122145648.jpg', name: 'IMG20220122145648' },
  { id: 'IMG20220122135454.jpg', src: 'assets/img/bottega/IMG20220122135454.jpg', name: 'IMG20220122135454' },
  { id: 'IMG20220122135626.jpg', src: 'assets/img/bottega/IMG20220122135626.jpg', name: 'IMG20220122135626' },
  { id: 'IMG20220122135706.jpg', src: 'assets/img/bottega/IMG20220122135706.jpg', name: 'IMG20220122135706' },
  { id: 'IMG20220122140601.jpg', src: 'assets/img/bottega/IMG20220122140601.jpg', name: 'IMG20220122140601' },
  { id: 'IMG20220122143034.jpg', src: 'assets/img/bottega/IMG20220122143034.jpg', name: 'IMG20220122143034' },
  { id: 'IMG20220122144522.jpg', src: 'assets/img/bottega/IMG20220122144522.jpg', name: 'IMG20220122144522' },
];

export default images;
