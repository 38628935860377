import { Box } from '@mui/material';
import React from 'react';
import Nav from '../Nav/index';

interface IHeader extends ILang {}

const Header: React.FC<IHeader> = ({ lang, setLang }) => {
  return (
    <Box>
      <Nav lang={lang} setLang={setLang} />
    </Box>
  );
};

export default Header;
