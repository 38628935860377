import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Menu,
  MenuItem,
  styled,
  ListItemIcon,
  ListItemText,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { db } from '../../firebase/firebase-config';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WineBarIcon from '@mui/icons-material/WineBar';
import StorefrontIcon from '@mui/icons-material/Storefront';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { getYPosition } from '../../utils/index';

const PAGES = [
  { it: 'Chi Siamo', en: 'About', icon: <InfoIcon /> },
  { it: 'Lo Staff', en: 'Our Team', icon: <GroupsIcon /> },
  { it: 'Menu', en: 'Menu', icon: <MenuBookIcon /> },
  { it: 'Vini', en: 'Wines', icon: <WineBarIcon /> },
  { it: 'La Bottega', en: 'The Shop', icon: <StorefrontIcon /> },
  { it: 'Camere', en: 'Rooms', icon: <BedroomParentIcon /> },
  { it: 'Contatti', en: 'Contacts', icon: <ContactPhoneIcon /> },
];

interface INav extends ILang {}

const ButtonLink = styled(Button)(({ theme }) => ({
  color: 'white',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const Lang: React.FC<ILang> = ({ lang, setLang }) => {
  const itaUrl =
    'https://firebasestorage.googleapis.com/v0/b/casa-barbabuc-95ff1.appspot.com/o/img%2Fitaly.ico?alt=media&token=91e8c676-6360-4541-b168-d6f73cc9ea7f';

  const enUrl =
    'https://firebasestorage.googleapis.com/v0/b/casa-barbabuc-95ff1.appspot.com/o/img%2Fenglish.ico?alt=media&token=ee0bd415-e3a4-453c-9b33-602b89c6b667';

  return (
    <>
      <IconButton
        onClick={() => setLang && setLang('it')}
        disabled={lang === 'it'}
        style={{ opacity: lang === 'it' ? 0.5 : 1, fontSize: '10px' }}
      >
        <img src={`${itaUrl}`} alt='it' width='30' height='30'></img>
      </IconButton>
      <IconButton
        onClick={() => setLang && setLang('en')}
        disabled={lang === 'en'}
        style={{ opacity: lang === 'en' ? 0.5 : 1, fontSize: '10px' }}
      >
        <img src={`${enUrl}`} alt='en' width='30' height='30'></img>
      </IconButton>
    </>
  );
};

const Nav: React.FC<INav> = ({ lang, setLang }) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const docRef = doc(db, 'immagini', 'logo.svg');
  const [value] = useDocumentData(docRef);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (id: string) => {
    const y = getYPosition(id, -90);
    window.scrollTo({ top: y, behavior: 'smooth' });
    setAnchorElNav(null);
  };

  return (
    <AppBar position='fixed' sx={{ height: '90px' }}>
      <Toolbar sx={{ height: '90px' }}>
        <Container maxWidth='lg' sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <img src={value?.url} alt='logo' width='64' height='64' />
          </Box>

          {/* Menu per small screen */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {PAGES.map((page, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    handleClick(`${page['en']}`.replaceAll(/\s/g, '').toLocaleLowerCase());
                  }}
                  sx={{ '&:hover': { color: 'secondary.main' } }}
                >
                  <ListItemIcon sx={{ color: 'inherit' }}>{page.icon}</ListItemIcon>
                  <ListItemText>{page[lang]}</ListItemText>
                </MenuItem>
              ))}
              <MenuItem>
                <Lang lang={lang} setLang={setLang} />
              </MenuItem>
            </Menu>
          </Box>

          {/* Menu desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {PAGES.map((page) => (
              <ButtonLink
                startIcon={page.icon}
                key={page.en}
                variant='text'
                onClick={() => {
                  handleClick(`${page['en']}`.replaceAll(/\s/g, '').toLocaleLowerCase());
                }}
              >
                {page[lang]}
              </ButtonLink>
            ))}
            <Lang lang={lang} setLang={setLang} />
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
