import React from 'react';
// import { useDocumentData } from 'react-firebase-hooks/firestore';
// import { db } from '../../firebase/firebase-config';
// import { doc } from 'firebase/firestore';
import styled from 'styled-components';
import '../../App.css';
import About from '../About/index';
import Title from '../Title';
import Menu from '../Menu';
import { itemsMenu, itemsVini } from '../../data';
import Bottega from '../Bottega';
import Camere from '../Camere';
import Contacts from '../Contacts';
import { Fab, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Map from '../Map';
import Footer from '../Footer';
import ScrollTop from '../ScrollTop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface IBody extends ILang {}

const Parallax = styled.div<{ url: string }>`
  height: 100vh;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.url});
`;

const Body: React.FC<IBody> = ({ lang }) => {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  // let docRef = doc(db, 'immagini', 'insegna.jpg');
  // const [insegna] = useDocumentData(docRef);

  // docRef = doc(db, 'immagini', 'staff.jpg');
  // const [staff] = useDocumentData(docRef);

  // docRef = doc(db, 'immagini', 'osteria.jpg');
  // const [osteria] = useDocumentData(docRef);

  // docRef = doc(db, 'immagini', 'vini.jpg');
  // const [vini] = useDocumentData(docRef);

  // docRef = doc(db, 'immagini', 'bottega.jpg');
  // const [bottega] = useDocumentData(docRef);

  return (
    <>
      <Toolbar id='backToTop' />
      <Parallax
        // url={insegna?.url}
        url={'assets/img/locali/insegna.jpg'}
        style={{ marginTop: '90px', ...(isExtraSmallScreen && { backgroundSize: '600px' }) }}
      />
      <About lang={lang} />
      <Title title={lang === 'it' ? 'Lo Staff' : 'The Staff'} id='ourteam' />
      <Parallax url={'assets/img/staff/staff.jpg'} />
      <Parallax
        url={'assets/img/locali/osteria.jpg'}
        style={{ marginTop: '50px', ...(isExtraSmallScreen && { backgroundSize: '600px' }) }}
      />
      <Menu lang={lang} title={lang === 'it' ? "L'Osteria" : 'The Osteria'} id='menu' items={itemsMenu} />
      <Parallax url={'assets/img/piatti/vini.jpg'} />
      <Menu lang={lang} title={lang === 'it' ? 'I Vini' : 'The Wines'} id='wines' items={itemsVini} />
      <Parallax
        url={'assets/img/locali/bottega.jpg'}
        style={{ ...(isExtraSmallScreen && { backgroundSize: '600px' }) }}
      />
      <Bottega id='theshop' title={lang === 'it' ? 'La Bottega' : 'The Shop'} lang={lang} />
      <Camere id='rooms' title={lang === 'it' ? 'Le nostre camere' : 'Our rooms'} lang={lang} />
      <Contacts id='contacts' lang={lang} />
      <Map />
      <Footer />
      <ScrollTop>
        <Fab size='small' sx={{ backgroundColor: 'primary.main', color: 'text.primary' }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default Body;
